@import "../../sass-utils/mixins.scss";
@import "../../sass-utils/variables.scss";

#bells {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__paragraph {
    width: 100%;
    text-align: justify;
  }
}
#Bells-S4 {
    flex-direction: column;

    .section {
      &__textarea {
        width: 100%;
      }
      &__articles {
        width: 100%;
        justify-content: space-around;

        .article {
          width: 25%;
        }
      }
    }
  }

