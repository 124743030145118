@import "../../sass-utils/mixins.scss";
@import "../../sass-utils/variables.scss";

#newsletters {
  .newsletters-container {
    position: relative;
    ul {
      flex-direction: column;
    }

    a {
      transition: all 0.3s ease-in-out;

      &:hover {
        transform: translateY(-0.15em);

        .newsletter {
          background: radial-gradient(
            circle,
            rgba(60, 160, 122, 1) 50%,
            rgba(144, 202, 180, 1) 100%
          );
        }
      }
    }
  }
}

@include desktop-layout {
  #newsletters {
    .newsletters-container {
      padding: 50px 0;
      ul > li {
        margin: calc(200px - (100px + $dotWidth / 2)) 0;
        .progressBar {
          flex-direction: column;
          margin: 0 calc((((1224px - 700px) / 2) / 2) - ($dotWidth/2)) 0
            calc((((1224px - 700px) / 2) / 2) - ($dotWidth/2));

          &__dot {
            height: $dotWidth;
            width: $dotWidth;
            background-color: $accent-color;
            border-radius: 50%;
            position: relative;

            &__top,
            &__bottom {
              position: absolute;
              width: 4px;
              height: 200px;
              background-color: $accent-color;
              left: calc(50% - 0.5px);
              transform: translateX(-50%);
            }

            &__top {
              top: calc(-1 * 200px + $dotWidth / 2);
            }

            &__bottom {
              bottom: calc(-1 * 200px + $dotWidth / 2);
            }
          }
        }

        a {
          text-decoration: none;

          .newsletter {
            border-radius: 100px;
            background-color: $primary-color;
            justify-content: space-between;
            width: 700px;
            overflow: hidden;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
              rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

              &__image {
                height: 200px;
                width: 200px;
                border-radius: 50%;
                background-position: center;
                background-size: 100%;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
                  rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
                  rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
              }

            &__text {
              max-height: calc(200px - 10px * 2);
              width: 400px;
              padding: 10px 80px 0 10px;

              &__title {
                position: relative;
                flex-direction: row;
                text-align: center;
                justify-content: space-around;
                padding: 20px;

                h4,
                span {
                  font-size: 30px;
                  color: $accent-color;
                }

                h4::after {
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  width: 15px;
                  height: 3px;
                  background-color: $active;
                  transform: translate(-50%);
                }
              }

              p {
                width: 100%;
                display: -webkit-box;
                text-overflow: ellipsis;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }
            }
          }
        }
      }
    }
  }
}

@include large-desktop-layout {
}

@include small-desktop-layout {
}

@include mobile-layout {
  #newsletters {
    .container {
      width: 100%;

      .newsletters-container {
        ul > li {
          margin: 10px 0;
          .progressBar {
            display: none;
          }

          a {
            text-decoration: none;
            width: 80%;

            .newsletter {
              border-radius: 100px;
              background: $secondary-color;
              justify-content: space-between;
              overflow: hidden;
              width: 100%;
              box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
                rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
                rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

              &__image {
                height: 100px;
                width: 100px;
                border-radius: 50%;
                background-position: center;
                background-size: 100%;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
                  rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
                  rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
              }

              &__text {
                width: calc(100% - 100px);

                &__title {
                  position: relative;
                  flex-direction: column;
                  text-align: center;
                  align-items: center;
                  justify-content: center;
                  height: 100px;

                  h4,
                  span {
                    font-size: 25px;
                    color: $accent-color;
                    padding: 10px 0;
                  }

                  h4::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 70%;
                    height: 1px;
                    background-color: $color-black;
                    transform: translate(-50%);
                  }
                }

                p {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
