@import "../../sass-utils/mixins.scss";
@import "../../sass-utils/variables.scss";

#donation {
  article {
    h2 {
      text-align: center;
    }

    p {
      text-align: justify;
    }

    .buttons-container {
      justify-content: space-evenly;
    }
  }
}

@include desktop-layout {
  #donation {
    article {
      h2 {
        margin-bottom: 50px;
      }
  
      h3 {
        margin-bottom: 50px;
      }
  
      p {
        margin-bottom: 50px;
      }
  
      .btn--donate {
        margin: 50px;
      }
    }
  }
}

@include large-desktop-layout {
}

@include small-desktop-layout {
}

@include mobile-layout {
  #donation {
    article {
      h2 {
        margin-bottom: 20px;
        background-color: $light-color;
        padding: 10px 0;
      }
  
      h3 {
        margin-bottom: 20px;
      }
  
      p {
        margin-bottom: 20px;
      }
  
      .buttons-container {
        flex-direction: column;
        margin: 10px 0;
        
        .btn--donate {
          margin: 10px 0;
        }
      }
    }
  }
}