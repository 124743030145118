@import "../../sass-utils/mixins.scss";
@import "../../sass-utils/variables.scss";

#join {
  .join {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__image {
      flex-wrap: wrap;
      width: 100%;

      &__wrapper {
        &__box {
          position: relative;
          border-radius: 4px;
          overflow: hidden;
          aspect-ratio: 4 / 3;
          box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px,
            rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
            rgba(0, 0, 0, 0.09) 0px -3px 5px;

          &__frame {
            position: absolute;
            border: 1px solid #fff;
            z-index: 2;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          h2,
          span {
            position: absolute;
            z-index: 2;
            width: 100%;
            transition: opacity 0.2s, transform 0.3s;
          }

          h2 {
            margin-bottom: 0;
            letter-spacing: 1px;
          }

          span {
            bottom: 0;
            letter-spacing: 1px;
          }
        }

        .blury-card {
          h2,
          span {
            text-align: center;
            top: 50%;
            margin: 0;
            z-index: 3;
          }
        }
      }
    }

    &__text {
      margin-top: 50px;
      p {
        text-align: justify;
      }
    }
  }
}

@include desktop-layout {
  .join {
    &__image {
      &__wrapper {
        width: 55%;
        height: auto;

        &__box {
          .hide {
            opacity: 0;
          }

          h2 {
            font-weight: 500;
          }

          span {
            font-size: 30px;
          }

          img {
            position: relative;
            height: 100%;
            width: 100%;
            background: {
              position: center;
              size: cover;
              repeat: no-repeat;
            }
            z-index: 1;
            transition: all 0.3s ease-in-out;

            &:hover {
              transition: all 0.3s ease-in-out;
            }

            &:after {
              content: "";
              position: absolute;
              background-color: rgba(0, 0, 0, 0.6);
              width: 100%;
              top: 0;
              left: 0;
              opacity: 0;
            }

            &:hover {
              transition: all 0.3s ease-in-out;
            }
          }
        }

        .blury-card {
          .join__image__wrapper__box__frame {
            width: calc(100% - 160px);
            height: calc(100% - 160px);
            opacity: 0;
            transition: all 0.3s ease-in-out;
          }

          h2,
          span {
            color: $color-white;
          }

          span {
            transform: translatey(30px);
          }

          h2 {
            transform: translatey(-30px);
          }

          &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background: #008e6b;
            z-index: 1;
            left: 0;
            opacity: 0;
            transition: all 0.3s ease-in-out;
          }

          img {
            z-index: 2;
          }

          &:hover {
            .join__image__wrapper__box__frame {
              opacity: 1;
            }

            img {
              opacity: 0.7;
            }

            &:before {
              opacity: 1;
              transition: all 0.3s ease-in-out;
            }
          }
        }
      }
    }
  }
}

@include large-desktop-layout {
}

@include small-desktop-layout {
}

@include mobile-layout {
  .join {
    &__image {
      &__wrapper {
        width: 100%;
        height: auto;

        &__box {
          .hide {
            opacity: 1;
          }

          h2 {
            font-weight: 500;
          }

          span {
            font-size: 30px;
          }

          img {
            position: relative;
            height: 100%;
            width: 100%;
            background: {
              position: center;
              size: cover;
              repeat: no-repeat;
            }
            z-index: 1;
            transition: all 0.3s ease-in-out;
          }
        }

        .blury-card {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 1;
          left: 0;

          .join__image__wrapper__box__frame {
            width: calc(100% - 40px);
            height: calc(100% - 40px);
          }

          span,
          h2 {
            font-size: 20px;
            color: $color-white;
            text-shadow:
            1px 1px 0 $color-black,
            -1px 1px 0 $color-black,
            -1px -1px 0 $color-black,
            1px -1px 0 $color-black;
        
          }

          span {
            transform: translatey(20px);
          }

          h2 {
            transform: translatey(-40px);
          }
        }
      }
    }
  }
}
