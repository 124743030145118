@import "../../sass-utils/mixins.scss";
@import "../../sass-utils/variables.scss";

.church {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__paragraph {
    width: 100%;
  }
}
