@import "../../sass-utils/mixins.scss";
@import "../../sass-utils/variables.scss";

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes progress-bar {
  from {
    width: 0%;
  }
  to {
    width: calc(100% * $ratioRaised);
  }
}

/* Firefox */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes progress-bar {
  from {
    width: 0%;
  }
  to {
    width: calc(100% * $ratioRaised);
  }
}

/* Safari and Chrome */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes progress-bar {
  from {
    width: 0%;
  }
  to {
    width: calc(100% * $ratioRaised);
  }
}

#project {
  h4 {
    text-align: center;
    font-family: "Pacifico", cursive;
    margin: 1rem 0 1rem 0;
  }

  .donation-progress {
    text-align: center;
    display: none;

    .progress-bg {
      margin: 0 auto;
      width: 100%;
      height: 50px;
      border-radius: 10px;
      text-align: center;
      -moz-box-shadow: inset 0 0 10px #ccc;
      -webkit-box-shadow: inset 0 0 10px #ccc;
      box-shadow: inset 0 0 10px #ccc;

      & .progress-bar {
        height: 50px;
        border-radius: 10px;
        float: left;
        width: calc(100% * $ratioRaised);
        overflow: hidden;
        /* fallback */
        background-color: #1c314a;

        /* Safari 4-5, Chrome 1-9 */
        background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#1c314a), to(#27425f));

        /* Safari 5.1, Chrome 10+ */
        background: -webkit-linear-gradient(top, #1c314a, #27425f);

        /* Firefox 3.6+ */
        background: -moz-linear-gradient(top, #1c314a, #27425f);
      }

      & .goal,
      & .raised {
        font-size: 1em;
        line-height: 50px;
        margin: 0;
        padding: 0;
        display: inline;
      }

      & .raised {
        color: #fff;
        margin: 14px 25px 0 0;
        padding: 0 25px 0 0;
        -webkit-animation: fadein 4s; /* Safari and Chrome */
        -moz-animation: fadein 4s; /* Firefox */
        animation: fadein 4s;
      }

      & .goal {
        color: #b2b2b2;
        text-align: center;
        float: right;
        display: inline;
        padding: 0 25px 0 0;
        text-align: center;
      }

      & div {
        -webkit-animation: progress-bar 2s ease forwards;
        -moz-animation: progress-bar 2s ease forwards;
        animation: progress-bar 2s ease forwards;
      }
    }
  }
}

@include desktop-layout {
  #project {
    article {
      position: relative;

      .donation-progress {
        position: absolute;
        top: 2600px;
        right: 0;
        width: 50%;
        text-align: center;

        .progress-bg {
          margin-bottom: 20px;
        }
      }
    }
  }
}

@include large-desktop-layout {
}

@include small-desktop-layout {
}

@include mobile-layout {
  #project {
    .donation-progress {
      .progress-bg {
        margin-bottom: 30px;
      }
    }
  }
}
