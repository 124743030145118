@import "../../sass-utils/mixins.scss";
@import "../../sass-utils/variables.scss";

.article {
  display: flex;

  h4 {
    font-family: $titles;
    padding: 20px 0;
    text-align: center;
    text-decoration: underline;
  }

  &__textarea {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    &__paragraph {
      text-align: justify;
    }
  }

  &__images {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__frame {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &:hover {
        cursor: pointer;
      }

      &__image {
        display: flex;
        justify-content: center;
        width: 100%;
        overflow: hidden;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;

        & img {
          display: block;
          width: 100%;
          transition: 0.3s;

          &:hover {
            transform: scale(1.1);
          }
        }
      }

      & span {
        font-size: 10px;
        width: 100%;
        text-align: center;
        margin-top: 20px;
      }
    }
  }
}

@include desktop-layout {
  .article {
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 40px 0;
    margin-bottom: 50px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

    &__textarea {
      &[data-images="0"] {
        width: 100%;
      }

      &[data-images="1"] {
        width: 50%;
      }

      &__title {
        margin-bottom: 30px;
        padding: 20px 0;
        text-align: center;
        background-color: $light-color;
      }

      &__button {
        display: flex;
        margin: 50px 20px 0 20px;
        width: 100%;
      }
    }

    &__images {
      width: 40%;

      &__frame {
        width: 100%;
        padding: 20px 0;
      }
    }

    &:nth-child(odd) {
      flex-direction: row;
      .article__textarea__button {
        justify-content: flex-start;
      }
    }

    &:nth-child(even) {
      flex-direction: row-reverse;
      .article__textarea__button {
        justify-content: flex-end;
      }
    }
  }
}

@include mobile-layout {
  .article {
    flex-direction: column;
    margin-top: 30px;
    padding-bottom: 30px;

    &__textarea {
      width: 100%;

      &__title {
        margin-bottom: 10px;
        text-align: start;
        font-style: italic;
      }

      &__paragraph {
        margin-bottom: 10px;
      }
    }

    &__images {
      width: 100%;

      &__frame {
        width: 80%;
        padding: 20px;
      }
    }
  }
}
