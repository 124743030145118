@import "../../sass-utils/mixins.scss";
@import "../../sass-utils/variables.scss";

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  background-color: $color-black;
  bottom: 0;
  border-top: solid 3px $accent-color;

  &__copyright {
    // font-family: $special;
    color: $color-white;
    text-align: center;
  }

  &__logo {
    width: 100px;
    height: 100px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
  }
}
