@import "../../sass-utils/mixins.scss";
@import "../../sass-utils/variables.scss";

@keyframes fade {
  from {
    opacity: 0;
  }

  50% {
    opacity: 0.3;
  }

  to {
    opacity: 1;
  }
}

.slider {
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(0deg, $light-color 0%, $primary-color 35%, $dark-color 100%);
  border: none;
  top: 0;
  left: 0;
  opacity: 0;

  &[data-open="1"] {
    animation: fade ease 1.5s forwards;
    visibility: visible;
    z-index: 4;
  }

  &[data-open="0"] {
    visibility: hidden;
    z-index: -2;
  }

  &__shut {
    position: absolute;
    top: 2%;
    right: 2%;
    width: $desktop-header-radius;
    height: $desktop-header-radius;
    background-color: transparent;
    opacity: 0.8;
    border: none;
    cursor: pointer;
    z-index: 4;

    &__line {
      position: absolute;
      left: 25%;
      width: 50%;
      height: 3px;
      background-color: $accent-color;
      border-radius: 10px;
      overflow: hidden;

      &:nth-child(1) {
        top: 30%;
        transform: translateY(calc($desktop-header-radius / 5)) rotate(45deg);
      }

      &:nth-child(2) {
        top: 70%;
        transform: translateY(calc($desktop-header-radius / -5)) rotate(-45deg);
      }
    }

    &:hover {
      opacity: 1;
    }
  }

  &__container {
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    z-index: 3;
    width: 100%;
    height: 100%;

    &__btn {
      position: absolute;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      z-index: 6;
      background-color: $accent-color;
      opacity: 0.5;
      animation: both 1s;
      border-radius: 50%;

      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    }

    & .hidden {
      display: none;
    }

    &__dots {
      position: absolute;
      width: 100%;

      & li {
        position: relative;
        display: inline-block;
        overflow: hidden;
        border-radius: 50%;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
          rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
        margin: 0 5px;

        & button {
          height: 100%;
          width: 100%;
          background: {
            color: $accent-color;
            size: auto 100%;
            position: center;
          }
          cursor: pointer;
        }

        & :hover {
          transform: scale(1.3);
        }
      }

      & .active {
        border: solid 2px $accent-color;
        transform: scale(1.2);
      }
    }
  }
}

@include desktop-layout {
  .slider__container {
    &__frame {
      width: 80%;
      height: 70%;
    }

    &__btn {
      top: 40%;

      &--next {
        right: 150px;
      }

      &--previous {
        left: 150px;
      }
    }

    &__dots {
      bottom: 60px;

      & li {
        height: 40px;
        width: 40px;
      }
    }
  }
}

@include mobile-layout {
  .slider__container {
    &__frame {
      width: 100%;
      max-width: 100%;
      height: 80%;
    }

    &__btn {
      display: none;
    }

    &__dots {
      bottom: 30px;

      & li {
        height: 20px;
        width: 20px;
      }
    }
  }
}

@include tablet-layout {
  .slider__container {
    &__frame {
      width: 80%;
      max-width: 80%;
      height: 70%;
    }

    &__btn {
      bottom: 9%;

      &--next {
        right: 40%;
      }

      &--previous {
        left: 40%;
      }
    }

    &__dots {
      bottom: 30px;

      & li {
        height: 50px;
        width: 50px;
      }
    }
  }
}
