@import "../../sass-utils/mixins.scss";
@import "../../sass-utils/variables.scss";

#history {
  .parent {
    width: 100%;
    height: 100%;
  }
  .input-container {
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    z-index: 0;
  }
  .input {
    width: $dotWidth;
    height: $dotWidth;
    background-color: $active;
    position: relative;
    border-radius: 50%;
    &:hover {
      cursor: pointer;
    }
    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      z-index: -1;
      background-color: $active;
    }
    &.active {
      background-color: $active;
      &::before {
        background-color: $active;
      }
      &::after {
        background-color: $inactive;
      }
      span {
        font-weight: 700;
      }
    }
    &.active ~ .input {
      &,
      &::before,
      &::after {
        background-color: $inactive;
      }
    }
    span {
      width: 1px;
      height: 1px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      visibility: hidden;
      &::before,
      &::after {
        visibility: visible;
        position: absolute;
      }
      &::after {
        content: attr(data-year);
      }
    }
  }
  .description-container {
    flex: 1 1 auto;

    .section {
      display: none;
      position: relative;
      overflow: hidden;

      .article {
        margin-top: 0;
        &__textarea {
          &__title {
            flex-direction: column;
            text-align: center;
            margin-bottom: 30px;
            padding: 40px 0 15px 0;
            border-bottom: solid 1px $accent-color;
          }

          &__paragraph {
            overflow: hidden;
          }
        }
      }

      &.active {
        display: block;
      }
    }
  }
  .gallery {
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 100px;

    &__frame {
      flex: 0 1 260px;
      position: relative;
      box-shadow: 10px 10px 30px -4px rgba(0, 0, 0, 0.75);
      border-radius: 40px;
      cursor: pointer;
      overflow: hidden;
      margin: 20px 0 20px 0;

      &__picture {
        width: 100%;
      }

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

@include desktop-layout {
  #history {
    .parent {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .input-container {
      top: 80px;
      flex-direction: row;
      width: 100vw;
      height: 100px;
      max-width: $parentMaxWidth;
    }
    .input {
      &::before,
      &::after {
        top: 50%;
        transform: translateY(-50%);
        width: calc($parentWidth / $numDots);
        height: 5px;
        max-width: calc($parentMaxWidth / $numDots);
      }
      &::before {
        right: $dotWidth;
      }
      &::after {
        left: $dotWidth;
      }
      &.active {
        span {
          &::before {
            font-size: 15px;
          }
          &::after {
            font-size: 18px;
          }
        }
      }
      span {
        &::before,
        &::after {
          left: 50%;
        }
        &::after {
          font-size: 14px;
          top: -50px;
          transform: translateX(-50%);
        }
      }
    }
    .description-container {
      .section {
        .article {
          box-shadow: none;
          padding: 0;
          &__textarea {
            &__paragraph {
              padding: 0 5rem 0 5rem;
            }
          }
        }

        &.active {
          display: block;
        }
      }
    }
    .gallery {
      align-items: center;
      width: 100%;
      justify-content: space-evenly;
      flex-wrap: wrap;

      &__frame {
        flex: 0 1 260px;
        position: relative;
        box-shadow: 10px 10px 30px -4px rgba(0, 0, 0, 0.75);
        border-radius: 40px;
        cursor: pointer;
        overflow: hidden;
        margin: 20px 0 20px 0;

        &__picture {
          width: 100%;
        }
      }
    }
  }
}

@include mobile-layout {
  #history {
    .parent {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    .input-container {
      flex-direction: row;
      height: 25px;
      width: 100%;
    }
    .input {
      &::before,
      &::after {
        top: 50%;
        transform: translateY(-50%);
        width: calc($parentWidth / $numDots);
        height: 5px;
      }
      &::before {
        right: $dotWidth;
      }
      &::after {
        left: $dotWidth;
      }
      &.active {
        span {
          &::after {
            font-size: 13px;
          }
        }
      }
      span {
        &::before,
        &::after {
          left: 50%;
        }
        &::after {
          font-size: 14px;
          top: -40px;
          transform: translateX(-50%);
        }
      }
    }
    .description-container {
      max-width: 90%;

      .section {
        .article {
          padding-bottom: 10px;
          &__textarea {
            &__title {
              text-align: center;
              height: 60px;

              & h2,
              h3,
              h4 {
                text-decoration: none;
              }

              & h2 {
                font-size: 20px;
                color: $accent-color;
                width: 100%;
              }
              & h3 {
                font-size: 15px;
                color: $accent-color;
              }
              & h4 {
                font-size: 10px;
                color: $accent-color;
              }
            }

            &__paragraph {
              padding: 0 0.5rem 0 0.5rem;

              &.show {
                padding-top: 20px;
              }
            }

            .toggle-btn {
              bottom: 5px;
              right: 50%;
              transform: translateX(50%);
              width: 105px;
              height: 35px;
              font-size: 13px;
              font-weight: 700;
            }
          }
        }
      }
    }
    .gallery {
      align-items: center;
      width: 100%;
      justify-content: space-evenly;
      flex-wrap: wrap;
      margin-top: 20px;

      &__frame {
        flex: 0 1 260px;
        position: relative;
        box-shadow: 10px 10px 30px -4px rgba(0, 0, 0, 0.75);
        border-radius: 40px;
        cursor: pointer;
        overflow: hidden;
        margin: 20px 0 20px 0;

        &__picture {
          width: 100%;
        }
      }
    }
  }
}
