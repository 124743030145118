@import "../../sass-utils/mixins.scss";
@import "../../sass-utils/variables.scss";

@include desktop-layout {
  #about {
    flex-direction: column;
  }
  
  #About-S2 {
    .section{
      &__textarea{
        position: relative;
        &__articles div {
          display: flex;
          justify-content: space-around;
  
          .article {
            width: 30%;

            &__images {
              position: absolute;
              width: 300px;
            }
  
            &__textarea {
              justify-content: flex-start;
              width: 100%;
            }
          }
        }
      }
    }
  }
  
  #About-S2A1 {
    margin-top: 0;

    .article__images {
      top: 20px;
      right: 50px;
    }
  }
  
  #About-S2A2 {
    margin-top: 300px;
  }
  
  #About-S2A3 {
    margin-top: 600px;

    .article__images {
      top: 765px;
      left: 50px;
    }
  }

}
